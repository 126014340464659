import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSelector } from 'react-redux';
import { axiosInstance } from '../../../utils/axiosInstance';

// ----------------------------------------------------------------------

NewClientForm.propTypes = {
  onSubmited: PropTypes.func,
};

export default function NewClientForm({ onSubmited }) {
  const RegisterSchema = Yup.object().shape({
    name: Yup.string().min(4, 'O nome é muito curto!').max(50, 'O nome é muito longo!').required('Nome é necessário'),
    accountNumber: Yup.number().required('Número de conta é necessário'),
    accountType: Yup.mixed().oneOf(['DEMO', 'REAL']).required('Tipo de conta é necessária'),
    validateDate: Yup.date().required('Data de validade é necessária'),
  });
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.user.user);

  const mutation = useMutation((newClient) => axiosInstance.post(`/users/${user.internal_id}/clients`, newClient), {
    onSuccess: (data) => {
      queryClient.setQueryData(['clients'], (oldData) => [...oldData, data.data]);
      enqueueSnackbar('Cliente criado', { variant: 'success' });
      onSubmited();
    },
    onError: () => {
      enqueueSnackbar('Falha ao criar cliente', { variant: 'error' });
    },
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      accountNumber: '',
      accountType: 'DEMO',
      validateDate: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      mutation.mutate({
        ...values,
        validateDate: values.validateDate.toISOString().split('T')[0],
        accountNumber: String(values.accountNumber),
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label="Nome Completo"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />

          <TextField
            fullWidth
            type="number"
            label="Número de conta"
            {...getFieldProps('accountNumber')}
            error={Boolean(touched.accountNumber && errors.accountNumber)}
            helperText={touched.accountNumber && errors.accountNumber}
          />

          <FormControl fullWidth>
            <InputLabel>Tipo de Conta</InputLabel>
            <Select label="Tipo de Conta" {...getFieldProps('accountType')}>
              <MenuItem value={'DEMO'}>Demo</MenuItem>
              <MenuItem value={'REAL'}>Real</MenuItem>
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Data de Validade"
              id="validateDate"
              name="validateDate"
              value={values.validateDate}
              inputFormat="dd/MM/yyyy"
              onChange={(val) => {
                setFieldValue('validateDate', val);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={mutation.isLoading}>
            {`Criar Cliente`}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
