import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { axiosInstance } from '../../../utils/axiosInstance';

// ----------------------------------------------------------------------

NewProductForm.propTypes = {
  onSubmited: PropTypes.func,
};

export default function NewProductForm({ onSubmited }) {
  const RegisterSchema = Yup.object().shape({
    code: Yup.string().min(2, 'O nome é muito curto!').required('Nome é necessário'),
  });
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.user.user);

  const mutation = useMutation(
    (newProduct) => axiosInstance.post(`v1/users/${user.internal_id}/products`, newProduct),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['products'], (oldData) => [
          ...oldData,
          { ...data.data, name: data.data.code, id: data.data.product_id },
        ]);
        enqueueSnackbar('Produto criado', { variant: 'success' });
        onSubmited();
      },
      onError: () => {
        enqueueSnackbar('Falha ao criar produto', { variant: 'error' });
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      mutation.mutate({
        ...values,
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label="Nome"
            {...getFieldProps('code')}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={mutation.isLoading}>
            {`Criar Produto`}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
