import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import NotFound from './pages/Page404';
import DashboardApp from './pages/DashboardApp';
// import User from './pages/User';
import ResetPassword from './pages/ResetPassword';
import NewClientLink from './pages/NewClientLink';
import Clients from './pages/Clients';
import Client from './pages/Client';
import Links from './pages/Links';
import NewClientLinkConfirmed from './pages/NewClientLinkConfirmed';
import NotFoundLink from './pages/Page404Link';
import Products from './pages/Products';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <DashboardApp /> },
        { path: 'clients', element: <Clients /> },
        { path: 'products', element: <Products /> },
        { path: 'client', element: <Client /> },
        { path: 'links', element: <Links /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '/create_account_link',
      children: [
        { path: '', element: <Navigate to="/404" /> },
        { path: ':clientid/:tokenid', element: <NewClientLink /> },
        { path: 'confirmed', element: <NewClientLinkConfirmed /> },
        { path: '404', element: <NotFoundLink /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '', element: <Navigate to="/login/" /> },
        { path: 'login', element: <Login /> },
        { path: 'reset_password', element: <ResetPassword /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
