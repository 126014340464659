import { Container, Dialog, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';

import React from 'react';
import NewLicenseForm from './NewLicenseForm';

NewLicenseDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  client: PropTypes.object,
  blackListProducts: PropTypes.array,
};

export default function NewLicenseDialog({ open, handleClose, client, blackListProducts }) {
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <Container sx={{ minWidth: 300, paddingBottom: 2 }}>
        <DialogTitle>Nova Licença</DialogTitle>
        <NewLicenseForm onSubmited={handleClose} client={client} blackListProducts={blackListProducts} />
      </Container>
    </Dialog>
  );
}
