// material
import { Card, Container, Typography } from '@mui/material';
// components
import { useLocation } from 'react-router-dom';
import Page from '../components/Page';
import EditClientForm from '../sections/@dashboard/client/EditClientForm';

// ----------------------------------------------------------------------

export default function Client() {
  const data = useLocation();

  return (
    <Page title="User">
      <Container>
        <>
          <Typography variant="h3" gutterBottom>
            {data.state.client?.name}
          </Typography>
          <Card sx={{ padding: 2, mb: 4 }}>
            <EditClientForm client={data.state.client} />
          </Card>
        </>
      </Container>
    </Page>
  );
}
