import { filter } from 'lodash';
import { useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@mui/material';
// components
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import ListHead from '../sections/@dashboard/list/ListHead';
import Scrollbar from '../components/Scrollbar';
import { LINKS_TABLE_HEAD } from '../sections/@dashboard/client/ClientLinks/LinksTableColumns';
import LinksMoreMenu from '../sections/@dashboard/client/ClientLinks/LinksMoreMenu';
import TableEmpty from '../components/TableEmpty';
import { axiosInstance } from '../utils/axiosInstance';
import Page from '../components/Page';
import SearchNotFound from '../components/SearchNotFound';
import TableFetchError from '../components/TableFetchError';
import Label from '../components/Label';
import { fromISOStringDateToBR } from '../utils/formatTime';

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (!array) return [];
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_clients) => _clients.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Links() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const user = useSelector((state) => state.user.user);

  const { isLoading, error, data } = useQuery(['links'], () =>
    axiosInstance.get(`/users/${user.internal_id}/clients/tokens`).then((res) => res.data)
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;

  const filteredClients = applySortFilter(data, getComparator(order, orderBy), filterName);

  const isClientNotFound = filteredClients.length === 0;

  return (
    <Page title="Links">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Links
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={LINKS_TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <TableBody>
                    {filteredClients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { clientId, clientToken } = row;
                      const isItemSelected = false;

                      return (
                        <TableRow
                          hover
                          key={clientId}
                          tabIndex={-1}
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="left">
                            <Typography
                              style={{ display: 'block', width: '30vw' }}
                              overflow="hidden"
                              variant="subtitle2"
                              noWrap
                            >
                              {`${process.env.REACT_APP_URL}/create_account_link/${clientId}/${clientToken.token}`}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            {fromISOStringDateToBR(new Date(clientToken.validUntil).toISOString().split('T')[0])}
                          </TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color={(clientToken.used && 'error') || 'success'}>
                              {clientToken.used ? 'Sim' : 'Não'}
                            </Label>
                          </TableCell>

                          <TableCell align="right">
                            <LinksMoreMenu link={row} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                )}

                {isClientNotFound && data?.length !== 0 && !isLoading && !error && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {isClientNotFound && data?.length === 0 && !isLoading && !error && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <TableEmpty searchQuery={'Link'} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {error && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <TableFetchError />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredClients.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
