import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Iconify from '../../../components/Iconify';
import { axiosInstance } from '../../../utils/axiosInstance';
import EditLicenseDialog from './EditLicenseDialog';

// ----------------------------------------------------------------------
LicenseMoreMenu.propTypes = {
  client: PropTypes.object,
  license: PropTypes.object,
};

export default function LicenseMoreMenu({ client, license }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [editLicenseDialogVisible, setEditLicenseDialogVisible] = useState(false);
  const currentUser = useSelector((state) => state.user.user);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const mutationDelete = useMutation(
    () =>
      axiosInstance.delete(
        `v1/users/${currentUser.internal_id}/clients/${client.clientId}/licenses/${license.ProductId}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['licences', client.clientId]).then(() => {
          enqueueSnackbar('Licença deletada.', { variant: 'success' });
        });
      },
      onError: () => {
        enqueueSnackbar('Error ao tentar deletar licença.', { variant: 'error' });
      },
    }
  );

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            setEditLicenseDialogVisible(true);
          }}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => {
            mutationDelete.mutate();
            setIsOpen(false);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Deletar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>

      <EditLicenseDialog
        open={editLicenseDialogVisible}
        handleClose={() => {
          setEditLicenseDialogVisible(false);
        }}
        client={client}
        license={license}
      />
    </>
  );
}
