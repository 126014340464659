// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// hooks
import withAnoum from '../HOC/withAnoum';
// components
import Page from '../components/Page';
// sections
import NewClientLinkForm from '../sections/@dashboard/client/NewClientLinkForm';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

function NewClientLink() {
  return (
    <Page title="New Client Link">
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              Digite suas informações
            </Typography>
            <NewClientLinkForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}

export default withAnoum(NewClientLink);
