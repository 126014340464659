import PropTypes from 'prop-types';
// material
import { Paper, Typography } from '@mui/material';

// ----------------------------------------------------------------------

TableEmpty.propTypes = {
  searchQuery: PropTypes.string,
  maleSubstantive: PropTypes.bool,
};

export default function TableEmpty({ searchQuery = '', maleSubstantive = true, ...other }) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        {maleSubstantive ? 'Nenhum' : 'Nenhuma'} <strong>&quot;{searchQuery}&quot;</strong>{' '}
        {maleSubstantive ? 'cadastrado' : 'cadastrada'} ainda.
      </Typography>
    </Paper>
  );
}
