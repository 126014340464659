import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSelector } from 'react-redux';
import { fromDateString } from '../../../utils/formatTime';
import { axiosInstance } from '../../../utils/axiosInstance';

// ----------------------------------------------------------------------

EditLicenseForm.propTypes = {
  onSubmited: PropTypes.func,
  client: PropTypes.object,
  license: PropTypes.object,
};

export default function EditLicenseForm({ onSubmited, client, license }) {
  const RegisterSchema = Yup.object().shape({
    accountType: Yup.mixed().oneOf(['DEMO', 'REAL']).required('Tipo de conta é necessária'),
    validateDate: Yup.date().required('Data de validade é necessária'),
  });

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.user.user);

  const mutationEdit = useMutation(
    (alteredLicense) =>
      axiosInstance.put(
        `v1/users/${user.internal_id}/clients/${client.clientId}/licenses/${license.ProductId}`,
        alteredLicense
      ),
    {
      onSuccess: (data) => {
        const newData = queryClient.getQueryData(['licences', client.clientId]);
        const num = newData.findIndex((value) => value.ProductId === data.data.ProductId);
        if (num >= 0) {
          newData[num] = { ...data.data, id: data.data.ProductId, name: data.data.Code };
        }
        queryClient.setQueryData(['licences', client.clientId], newData);
        enqueueSnackbar('Licença alterado.', { variant: 'success' });
        onSubmited();
      },
      onError: () => {
        enqueueSnackbar('Error ao tentar mudar licença.', { variant: 'error' });
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      accountType: license.Demo ? 'DEMO' : 'REAL',
      validateDate: fromDateString(license.ValidUntil),
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      mutationEdit.mutate({
        validUntil: values.validateDate.toISOString().split('T')[0],
        demo: values.accountType === 'DEMO',
      });
    },
  });

  const { handleSubmit, getFieldProps, values, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <FormControl fullWidth>
            <InputLabel>Tipo de Conta</InputLabel>
            <Select label="Tipo de Conta" {...getFieldProps('accountType')}>
              <MenuItem value={'DEMO'}>Demo</MenuItem>
              <MenuItem value={'REAL'}>Real</MenuItem>
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Data de Validade"
              id="validateDate"
              name="validateDate"
              value={values.validateDate}
              inputFormat="dd/MM/yyyy"
              onChange={(val) => {
                console.log(typeof val);
                setFieldValue('validateDate', val);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={mutationEdit.isLoading}>
            {`Salvar Licença`}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
