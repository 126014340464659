import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Iconify from '../../../components/Iconify';
import { axiosInstance } from '../../../utils/axiosInstance';

// ----------------------------------------------------------------------
ClientMoreMenu.propTypes = {
  client: PropTypes.object,
};

export default function ClientMoreMenu({ client }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const currentUser = useSelector((state) => state.user.user);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const mutationDelete = useMutation(
    () => axiosInstance.delete(`/users/${currentUser.internal_id}/clients/${client.clientId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['clients']).then(() => {
          enqueueSnackbar('Cliente deletado.', { variant: 'success' });
        });
      },
      onError: () => {
        enqueueSnackbar('Error ao tentar deletar cliente.', { variant: 'error' });
      },
    }
  );

  const mutationStatus = useMutation(
    (alteredUser) => axiosInstance.put(`/users/${currentUser.internal_id}/clients/${client.clientId}`, alteredUser),
    {
      onSuccess: (data) => {
        const newData = queryClient.getQueryData(['clients']);
        const num = newData.findIndex((value) => value.clientId === data.data.clientId);
        if (num >= 0) {
          newData[num] = data.data;
        }
        queryClient.setQueryData(['clients'], newData);
        enqueueSnackbar('Status alterado.', { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar('Error ao tentar mudar status.', { variant: 'error' });
      },
    }
  );

  const changeStatus = (status) => {
    mutationStatus.mutate({
      name: client.name,
      validateDate: client.validateDate,
      config: client.config ? client.config : {},
      accountType: client.accountType,
      accountNumber: client.accountNumber,
      active: status,
    });
    setIsOpen(false);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem component={RouterLink} state={{ client }} to={`/dashboard/client`} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {!client.active ? (
          <MenuItem
            sx={{ color: 'text.secondary' }}
            onClick={() => {
              changeStatus(true);
            }}
          >
            <ListItemIcon>
              <Iconify icon="bi:person-check-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Ativar" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        ) : (
          <MenuItem
            sx={{ color: 'text.secondary' }}
            onClick={() => {
              changeStatus(false);
            }}
          >
            <ListItemIcon>
              <Iconify icon="bi:person-x-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Desativar" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => {
            mutationDelete.mutate();
            setIsOpen(false);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Deletar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
