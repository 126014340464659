// components
import Page from '../components/Page';
import ProductList from '../sections/@dashboard/product/ProductList';

// ----------------------------------------------------------------------

export default function Products() {
  return (
    <Page title="Products">
      <ProductList />
    </Page>
  );
}
