import ClientList from '../sections/@dashboard/client/ClientList';
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function Clients() {
  return (
    <Page title="Clients">
      <ClientList />
    </Page>
  );
}
