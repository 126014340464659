import { Container, Dialog, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';

import React from 'react';
import EditLicenseForm from './EditLicenseForm';

EditLicenseDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  client: PropTypes.object,
  license: PropTypes.object,
};

export default function EditLicenseDialog({ open, handleClose, client, license }) {
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <Container sx={{ minWidth: 300, paddingBottom: 2 }}>
        <DialogTitle>{`Editar Licença: ${license.Code}`}</DialogTitle>
        <EditLicenseForm onSubmited={handleClose} client={client} license={license} />
      </Container>
    </Dialog>
  );
}
