import { Container, Dialog, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';

import React from 'react';
import NewClientForm from './NewClientForm';

NewClientDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default function NewClientDialog({ open, handleClose }) {
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <Container sx={{ minWidth: 300, paddingBottom: 2 }}>
        <DialogTitle>Novo Cliente</DialogTitle>
        <NewClientForm onSubmited={handleClose} />
      </Container>
    </Dialog>
  );
}
