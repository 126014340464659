/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosInstance } from '../utils/axiosInstance';

const withValidNewUserLink = (WrappedComponent) => (props) => {
  const [proceed, setProceed] = useState(false);
  const navigation = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      axiosInstance
        .get(`/clients/${params.clientid}/verify-token?token=${params.tokenid}`)
        .then((res) => {
          if (res.data.used || new Date(res.data.validUntil) < new Date()) {
            navigation('/create_account_link/404', { replace: true });
          }
        })
        .catch(() => {
          navigation('/create_account_link/404', { replace: true });
        });
    }
    setProceed(true);
  }, []);

  if (proceed) return <WrappedComponent {...props} />;
  return null;
};

export default withValidNewUserLink;
