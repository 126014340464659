// @mui
import { Grid, Container, Typography, CircularProgress } from '@mui/material';
// components
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { axiosInstance } from '../utils/axiosInstance';
import Page from '../components/Page';
// sections
import { AppWidgetSummary } from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const user = useSelector((state) => state.user.user);
  const { isLoading, data } = useQuery(['dashboard_data'], () =>
    axiosInstance
      .get(`/users/${user.internal_id}/clients/count`)
      .then((res) => res.data)
      .catch((error) => {
        console.log(error);
      })
  );
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Olá, Bem vindo de volta
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Cliente Ativos"
              total={isLoading ? <CircularProgress /> : data.active}
              icon={'bi:people-fill'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Cliente Inativos"
              total={isLoading ? <CircularProgress /> : data.inactive}
              color="info"
              icon={'bi:people-fill'}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
