import { filter } from 'lodash';
import { useState } from 'react';
import PropTypes from 'prop-types';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@mui/material';
// components
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { sentenceCase } from 'change-case';
import Label from '../../../components/Label';
import { fromDateString, fromISOStringDateToBR } from '../../../utils/formatTime';
import NewLicenseDialog from './NewLicenseDialog';
import LicenseMoreMenu from './LicenseMoreMenu';
import TableEmpty from '../../../components/TableEmpty';
import { axiosInstance } from '../../../utils/axiosInstance';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import SearchNotFound from '../../../components/SearchNotFound';
import TableFetchError from '../../../components/TableFetchError';
import { LICENSE_TABLE_HEAD } from './LicenseTableColumns';
import ListToolbar from '../list/ListToolbar';
import ListHead from '../list/ListHead';

// ----------------------------------------------------------------------
LicenseList.propTypes = {
  client: PropTypes.object,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (!array) return [];
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_licenses) => _licenses.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function LicenseList({ client }) {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [newLicenseDialogVisible, setNewLicenseDialogVisible] = useState(false);
  const user = useSelector((state) => state.user.user);

  const { isLoading, error, data } = useQuery(['licences', client.clientId], () =>
    axiosInstance
      .get(`v1/users/${user.internal_id}/clients/${client.clientId}/licenses`)
      .then((res) => res.data.map((value) => ({ ...value, id: value.ProductId, name: value.Code })))
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;

  const filteredLicenses = applySortFilter(data, getComparator(order, orderBy), filterName);

  const isLicenseNotFound = filteredLicenses.length === 0;

  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" gutterBottom>
          Licenças de produtos
        </Typography>
        <Stack direction="row">
          <Button
            onClick={() => setNewLicenseDialogVisible(true)}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Adicionar Licença de produto
          </Button>
        </Stack>
      </Stack>

      <Card>
        <ListToolbar
          placeholderSearch="Procurar licença..."
          numSelected={0}
          filterName={filterName}
          onFilterName={handleFilterByName}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={LICENSE_TABLE_HEAD}
                onRequestSort={handleRequestSort}
              />
              {isLoading ? (
                <CircularProgress />
              ) : (
                <TableBody>
                  {filteredLicenses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { name, id, ValidUntil, Demo } = row;
                    const isItemSelected = false;
                    const date = fromDateString(ValidUntil);
                    const isExpired = date < new Date();

                    return (
                      <TableRow hover key={id} tabIndex={-1} selected={isItemSelected} aria-checked={isItemSelected}>
                        <TableCell component="th" scope="row">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{id}</TableCell>
                        <TableCell align="left">{fromISOStringDateToBR(ValidUntil)}</TableCell>
                        <TableCell align="left">{Demo ? 'Demo' : 'Real'}</TableCell>
                        <TableCell align="left">
                          <Label variant="ghost" color={(isExpired && 'error') || 'success'}>
                            {isExpired ? sentenceCase('Inativo: Expirou data') : sentenceCase('Ativo')}
                          </Label>
                        </TableCell>
                        <TableCell align="right">
                          <LicenseMoreMenu client={client} license={row} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              )}

              {isLicenseNotFound && data?.length !== 0 && !isLoading && !error && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

              {isLicenseNotFound && data?.length === 0 && !isLoading && !error && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <TableEmpty maleSubstantive={false} searchQuery={'Licença'} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

              {error && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <TableFetchError />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredLicenses.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <NewLicenseDialog
        open={newLicenseDialogVisible}
        handleClose={() => {
          setNewLicenseDialogVisible(false);
        }}
        client={client}
        blackListProducts={data}
      />
    </Stack>
  );
}
