import {
  inMemoryPersistence,
  signInWithEmailAndPassword as signInWithEmailAndPasswordFirebase,
  onAuthStateChanged,
  setPersistence,
  signOut as signOutFirebase,
} from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { axiosInstance, axiosInstanceVerify } from '../utils/axiosInstance';
import { signInUser, signOutUser } from '../store/user/slice';
import { auth } from '../firebase/FirebaseConnector';
import { store } from '../store/store';

const formatAuthUser = (user, internalUser, tokenClaims) => ({
  uid: user.uid,
  email: user.email,
  name: internalUser.name,
  role: tokenClaims.claims.role,
  internal_id: tokenClaims.claims.internal_id,
  token: tokenClaims.token,
  cpf: internalUser.cpf,
  active: internalUser.active,
  refreshToken: user.refreshToken,
  expirationTime: tokenClaims.expirationTime,
});

let AUTH_INTERCEPTOR = null;

export function useFirebaseAuth() {
  const [authUser, setAuthUser] = useState(null);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [startLoading, setStartLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { isSignedIn } = useSelector((state) => ({
    isSignedIn: state.user.isSignedIn,
  }));

  const interceptorAuth = async (config) => {
    const { user } = store.getState().user;
    if (new Date(user.expirationTime).getTime() - new Date().getTime() <= 1000 * 50) {
      const newToken = await refreshToken();
      dispatch(signInUser({ ...user, token: newToken.token, expirationTime: newToken.expirationTime }));
      config.headers.Authorization = `Bearer ${newToken.token}`;
    } else {
      config.headers.Authorization = `Bearer ${user.token}`;
    }
    return config;
  };

  const authStateChanged = async (authState) => {
    if (!authState) {
      clear();
      dispatch(signOutUser());
      return;
    }
    if (isSignedIn) return;
    const tokenResult = await authState.getIdTokenResult();

    const resultVerify = await axiosInstanceVerify.get('/verify', {
      headers: {
        Authorization: `Bearer ${tokenResult.token}`,
      },
    });
    if (resultVerify.status === 200) {
      const resultInformation = await axiosInstanceVerify.get(`/users/${tokenResult.claims.internal_id}`, {
        headers: {
          Authorization: `Bearer ${tokenResult.token}`,
        },
      });
      if (resultInformation.data.active) {
        dispatch(signInUser(formatAuthUser(authState, resultInformation.data, tokenResult)));
        axiosInstance.interceptors.request.use(interceptorAuth);
        navigate('/dashboard', { replace: true });
        enqueueSnackbar('Autenticado', { variant: 'success' });
      } else {
        enqueueSnackbar('Você não tem permissão.', { variant: 'error' });
        signOut();
      }
    } else {
      enqueueSnackbar('Erro ao tentar autenticar, verifique seu email e senha.', { variant: 'error' });
      signOut();
    }
    setLoading(false);
    setStartLoading(false);
  };
  // listen for Firebase state change
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, authStateChanged);
    return () => unsubscribe();
  }, []);

  const refreshToken = async () => {
    const tokenResult = await auth.currentUser.getIdTokenResult(true);
    const resultVerify = await axiosInstanceVerify.get('/verify', {
      headers: {
        Authorization: `Bearer ${tokenResult.token}`,
      },
    });
    if (resultVerify.status === 200) {
      return tokenResult;
    }
    signOut();
    return '';
  };

  const signInWithEmailAndPassword = async (email, password) => {
    setLoading(true);
    try {
      await setPersistence(auth, inMemoryPersistence);
      await signInWithEmailAndPasswordFirebase(auth, email, password);
    } catch (err) {
      console.log(err);
      setError(err);
      setLoading(false);
      enqueueSnackbar('Erro ao tentar autenticar, verifique seu email e senha.', { variant: 'error' });
    }
  };

  const clear = () => {
    setAuthUser(null);
    setLoading(false);
    setStartLoading(false);
    if (AUTH_INTERCEPTOR) {
      axiosInstance.interceptors.request.eject(AUTH_INTERCEPTOR);
      AUTH_INTERCEPTOR = null;
    }
  };

  const signOut = async () => {
    try {
      await signOutFirebase(auth);
    } finally {
      clear();
      dispatch(signOutUser());
      navigate('/', { replace: true });
    }
  };

  return {
    authUser,
    loading,
    startLoading,
    error,
    signOut,
    signInWithEmailAndPassword,
  };
}
