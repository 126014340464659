import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSelector } from 'react-redux';
import { axiosInstance } from '../../../utils/axiosInstance';

// ----------------------------------------------------------------------

NewLicenseForm.propTypes = {
  onSubmited: PropTypes.func,
  client: PropTypes.object,
  blackListProducts: PropTypes.array,
};

export default function NewLicenseForm({ onSubmited, client, blackListProducts }) {
  const RegisterSchema = Yup.object().shape({
    productId: Yup.string().min(5, 'Produto é necessário').required('Produto é necessário'),
    accountType: Yup.mixed().oneOf(['DEMO', 'REAL']).required('Tipo de conta é necessária'),
    validateDate: Yup.date().required('Data de validade é necessária'),
  });

  const {
    isLoading: isLoadingProducts,
    error: errorProducts,
    data: dataProducts,
  } = useQuery(['products'], () =>
    axiosInstance
      .get(`v1/users/${user.internal_id}/products`)
      .then((res) =>
        res.data
          .filter((value) => blackListProducts.findIndex((value2) => value2.id === value.product_id) === -1)
          .map((value) => ({ ...value, name: value.code, id: value.product_id }))
      )
  );

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.user.user);

  const mutation = useMutation(
    (newLicense) =>
      axiosInstance.post(`v1/users/${user.internal_id}/clients/${client.clientId}/licenses/${newLicense.productId}`, {
        demo: newLicense.demo,
        validUntil: newLicense.validUntil,
      }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['licences', client.clientId], (oldData) => [
          ...oldData,
          { ...data.data, id: data.data.ProductId, name: data.data.Code },
        ]);
        enqueueSnackbar('Licença criada', { variant: 'success' });
        onSubmited();
      },
      onError: () => {
        enqueueSnackbar('Falha ao criar licença', { variant: 'error' });
      },
    }
  );

  const getProductSelectValues = () => {
    if (isLoadingProducts) {
      return <MenuItem value="">Carregando...</MenuItem>;
    }
    if (errorProducts || !dataProducts) {
      return <MenuItem value="">Erro procurando produtos</MenuItem>;
    }
    if (dataProducts.length === 0) {
      return <MenuItem value="">Nenhum produto encontrado</MenuItem>;
    }
    return dataProducts.map((product) => (
      <MenuItem key={product.id} value={product.id}>
        {product.name}
      </MenuItem>
    ));
  };

  const formik = useFormik({
    initialValues: {
      productId: '',
      accountType: 'DEMO',
      validateDate: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      mutation.mutate({
        productId: values.productId,
        validUntil: values.validateDate.toISOString().split('T')[0],
        demo: values.accountType === 'DEMO',
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <FormControl fullWidth>
            <InputLabel>Produto</InputLabel>
            <Select
              label="Produto"
              {...getFieldProps('productId')}
              error={Boolean(touched.productId && errors.productId)}
            >
              {getProductSelectValues()}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Tipo de Conta</InputLabel>
            <Select label="Tipo de Conta" {...getFieldProps('accountType')}>
              <MenuItem value={'DEMO'}>Demo</MenuItem>
              <MenuItem value={'REAL'}>Real</MenuItem>
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Data de Validade"
              id="validateDate"
              name="validateDate"
              value={values.validateDate}
              inputFormat="dd/MM/yyyy"
              onChange={(val) => {
                setFieldValue('validateDate', val);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={mutation.isLoading}>
            {`Criar Licença`}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
