import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
// material
import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TableEmpty from '../../../components/TableEmpty';
import { axiosInstance } from '../../../utils/axiosInstance';
import Iconify from '../../../components/Iconify';
import LicenseList from '../licenses/LicenseList';

// ----------------------------------------------------------------------

EditClientForm.propTypes = {
  client: PropTypes.object,
};

export default function EditClientForm({ client }) {
  const RegisterSchema = Yup.object().shape({
    name: Yup.string().min(2, 'O nome é muito curto!').max(50, 'O nome é muito longo!').required('Nome é necessário'),
    accountNumber: Yup.number().required('Número de conta é necessário'),
    accountType: Yup.mixed().oneOf(['DEMO', 'REAL']).required('Tipo de conta é necessária'),
    validateDate: Yup.date().required('Data de validade é necessária'),
    config: Yup.mixed(),
  });
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const user = useSelector((state) => state.user.user);
  const navigation = useNavigate();

  const mutation = useMutation(
    (userUpdate) => axiosInstance.put(`/users/${user.internal_id}/clients/${client.clientId}`, userUpdate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['clients']).then(() => {
          enqueueSnackbar('Cliente alterado', { variant: 'success' });
          navigation('/dashboard/clients');
        });
      },
      onError: () => {
        enqueueSnackbar('Falha ao alterar cliente', { variant: 'error' });
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      name: client.name,
      accountNumber: client.accountNumber,
      accountType: client.accountType,
      validateDate: new Date(`${client.validateDate}T00:00:00`),
      config: client.config ? Object.entries(client.config) : [],
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      const configValues = values.config.reduce((accumulator, currentValue) => {
        if (!currentValue[0] || !currentValue[1]) return accumulator;
        accumulator[currentValue[0]] = currentValue[1];
        return accumulator;
      }, {});
      mutation.mutate({
        ...client,
        ...values,
        validateDate: values.validateDate.toISOString().split('T')[0],
        accountNumber: String(values.accountNumber),
        config: configValues,
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue, values } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label="Nome Completo"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />

          <TextField
            fullWidth
            type="number"
            label="Número de conta"
            {...getFieldProps('accountNumber')}
            error={Boolean(touched.accountNumber && errors.accountNumber)}
            helperText={touched.accountNumber && errors.accountNumber}
          />

          <FormControl fullWidth>
            <InputLabel>Tipo de Conta</InputLabel>
            <Select label="Tipo de Conta" {...getFieldProps('accountType')}>
              <MenuItem value={'DEMO'}>Demo</MenuItem>
              <MenuItem value={'REAL'}>Real</MenuItem>
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Data de Validade"
              id="validateDate"
              name="validateDate"
              value={values.validateDate}
              inputFormat="dd/MM/yyyy"
              onChange={(val) => {
                setFieldValue('validateDate', val);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>

          <LicenseList client={client} />

          <FieldArray
            {...getFieldProps('config')}
            render={(arrayHelpers) => (
              <>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                  <Typography variant="h4" gutterBottom>
                    Configurações Customizáveis
                  </Typography>
                  <Button
                    onClick={() => arrayHelpers.push(['', ''])}
                    sx={{ marginRight: 2 }}
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                  >
                    Nova Configuração
                  </Button>
                </Stack>
                {values.config.length === 0 && <TableEmpty maleSubstantive={false} searchQuery="Configuração" />}
                {values.config.map((value, index) => (
                  <Stack key={index} direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <TextField sx={{ marginRight: 2 }} label="Chave" {...getFieldProps(`config.${index}[0]`)} />
                    <TextField sx={{ marginRight: 2 }} label="Valor" {...getFieldProps(`config.${index}[1]`)} />
                    <Button
                      onClick={() => arrayHelpers.remove(index)}
                      sx={{ marginRight: 2 }}
                      variant="contained"
                      color="error"
                      startIcon={<Iconify icon="eva:trash-2-outline" />}
                    >
                      Apagar
                    </Button>
                  </Stack>
                ))}
              </>
            )}
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={mutation.isLoading}>
            {`Salvar`}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
