// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Início',
    path: '/dashboard/',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Produtos',
    path: '/dashboard/products/',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Clientes',
    path: '/dashboard/clients',
    icon: getIcon('bi:people-fill'),
  },
  {
    title: 'Links',
    path: '/dashboard/links',
    icon: getIcon('eva:link-2-fill'),
  },
];

export default navConfig;
