import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosInstance } from '../../../utils/axiosInstance';
import withValidNewUserLink from '../../../HOC/withValidNewUserLink';

// ----------------------------------------------------------------------

function NewClientLinkForm() {
  const RegisterSchema = Yup.object().shape({
    name: Yup.string().min(2, 'O nome é muito curto!').max(50, 'O nome é muito longo!').required('Nome é necessário'),
    accountNumber: Yup.number().required('Número de conta é necessário'),
    accountType: Yup.mixed().oneOf(['DEMO', 'REAL']).required('Tipo de conta é necessária'),
  });
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigation = useNavigate();

  const mutation = useMutation((newUser) => axiosInstance.patch(`/clients/${params.clientid}`, newUser), {
    onSuccess: () => {
      enqueueSnackbar('Cadastro confirmado', { variant: 'success' });
      navigation('/create_account_link/confirmed');
    },
    onError: () => {
      enqueueSnackbar('Falha ao tentar cadastrar', { variant: 'error' });
    },
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      accountNumber: '',
      accountType: 'DEMO',
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      mutation.mutate({ ...values, accountNumber: String(values.accountNumber), token: params.tokenid });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label="Nome Completo"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />

          <TextField
            fullWidth
            type="number"
            label="Número de conta"
            {...getFieldProps('accountNumber')}
            error={Boolean(touched.accountNumber && errors.accountNumber)}
            helperText={touched.accountNumber && errors.accountNumber}
          />

          <FormControl fullWidth>
            <InputLabel>Tipo de Conta</InputLabel>
            <Select label="Tipo de Conta" {...getFieldProps('accountType')}>
              <MenuItem value={'DEMO'}>Demo</MenuItem>
              <MenuItem value={'REAL'}>Real</MenuItem>
            </Select>
          </FormControl>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={mutation.isLoading}>
            {`Enviar`}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

export default withValidNewUserLink(NewClientLinkForm);
