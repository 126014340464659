import { Container, Dialog, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';

import React from 'react';
import NewProductForm from './NewProductForm';

NewProductDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default function NewProductDialog({ open, handleClose }) {
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <Container sx={{ minWidth: 300, paddingBottom: 2 }}>
        <DialogTitle>Novo produto</DialogTitle>
        <NewProductForm onSubmited={handleClose} />
      </Container>
    </Dialog>
  );
}
