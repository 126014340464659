import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fromDateString(date) {
  const split = date.split('-');
  return new Date(Number(split[0]),Number(split[1])-1,Number(split[2]));
}

export function fromISOStringDateToBR(date) {
  const split = date.split('-');
  return `${split[2]}/${split[1]}/${split[0]}`;
}
