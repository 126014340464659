import { filter } from 'lodash';
import { useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@mui/material';
// components
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { sentenceCase } from 'change-case';
import { useSnackbar } from 'notistack';
import ListHead from '../list/ListHead';
import TableEmpty from '../../../components/TableEmpty';
import NewClientLinkDialog from './NewClientLinkDialog';
import { axiosInstance } from '../../../utils/axiosInstance';
import NewClientDialog from './NewClientDialog';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import SearchNotFound from '../../../components/SearchNotFound';
import { ClientMoreMenu } from '.';
import { CLIENT_TABLE_HEAD } from './ClientTableColumns';
import TableFetchError from '../../../components/TableFetchError';
import Label from '../../../components/Label';
import { fromISOStringDateToBR, fromDateString } from '../../../utils/formatTime';
import ListToolbar from '../list/ListToolbar';

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (!array) return [];
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_clients) => _clients.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ClientList() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [newClientDialogVisible, setNewClientDialogVisible] = useState(false);
  const [newClientLinkDialogVisible, setNewClientLinkDialogVisible] = useState(false);
  const [newClientLink, setNewClientLink] = useState('');
  const user = useSelector((state) => state.user.user);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading, error, data } = useQuery(['clients'], () =>
    axiosInstance.get(`/users/${user.internal_id}/clients`).then((res) => res.data)
  );

  const mutation = useMutation(() => axiosInstance.post(`/users/${user.internal_id}/clients/new-token`), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['links']).then(() => {
        enqueueSnackbar('Link criado', { variant: 'success' });
        setNewClientLink(
          `${process.env.REACT_APP_URL}/create_account_link/${data.data.clientId}/${data.data.clientToken.token}`
        );
        setNewClientLinkDialogVisible(true);
      });
    },
    onError: () => {
      enqueueSnackbar('Falha ao criar link', { variant: 'error' });
    },
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;

  const filteredClients = applySortFilter(data, getComparator(order, orderBy), filterName);

  const isClientNotFound = filteredClients.length === 0;

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Clientes
        </Typography>
        <Stack direction="row">
          <Button
            sx={{ marginRight: 2 }}
            onClick={() => mutation.mutate()}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Criar link de novo cliente
          </Button>
          <Button
            onClick={() => setNewClientDialogVisible(true)}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Novo cliente
          </Button>
        </Stack>
      </Stack>

      <Card>
        <ListToolbar
          placeholderSearch="Procurar cliente..."
          numSelected={0}
          filterName={filterName}
          onFilterName={handleFilterByName}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={CLIENT_TABLE_HEAD}
                onRequestSort={handleRequestSort}
              />
              {isLoading ? (
                <CircularProgress />
              ) : (
                <TableBody>
                  {filteredClients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { name, accountNumber, accountType, validateDate, active } = row;
                    const isItemSelected = false;
                    const date = fromDateString(validateDate);
                    const isExpired = date < new Date();

                    return (
                      <TableRow
                        hover
                        key={accountNumber}
                        tabIndex={-1}
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell component="th" scope="row">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{accountNumber}</TableCell>
                        <TableCell align="left">{sentenceCase(accountType)}</TableCell>
                        <TableCell align="left">{fromISOStringDateToBR(validateDate)}</TableCell>
                        <TableCell align="left">
                          <Label variant="ghost" color={((!active || isExpired) && 'error') || 'success'}>
                            {!active || isExpired
                              ? `${sentenceCase('Inativo')}${isExpired ? ': Expirou data' : ''}`
                              : sentenceCase('Ativo')}
                          </Label>
                        </TableCell>

                        <TableCell align="right">
                          <ClientMoreMenu client={row} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              )}

              {isClientNotFound && data?.length !== 0 && !isLoading && !error && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

              {isClientNotFound && data?.length === 0 && !isLoading && !error && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <TableEmpty searchQuery={'Cliente'} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

              {error && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <TableFetchError />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredClients.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <NewClientDialog
        open={newClientDialogVisible}
        handleClose={() => {
          setNewClientDialogVisible(false);
        }}
      />

      <NewClientLinkDialog
        open={newClientLinkDialogVisible}
        link={newClientLink}
        handleClose={() => {
          setNewClientLinkDialogVisible(false);
        }}
      />
    </Container>
  );
}
